import { Injectable } from '@angular/core';
import { Settings } from 'http2';

@Injectable()
export class Globals {
  id: string = null;
  clientid: string = null;
  emailid: string = null;
  fullname: string = null;
  countryCode: string = null;
  seatId:string = null;
  role: string = null;
  privilegeId:string = null;
  menu: any[] = [];
  token: string = null;
  seatDetails: {} = {};
  vertozDomain: boolean;
  seatDomainName:string = null;
  teamLead: boolean;
  newSignup:boolean;
  loader:string = null;
  isShowMediaPlanning:boolean;
  isJavaScriptCreative:boolean;
  s2sEnable:boolean;
  s2sDomainName:string = null;
}
